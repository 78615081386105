
@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

$animationSpeed: 4s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 3))}
}


// Styling
.slider {
	height: 100px;
	margin: auto;
	position: relative;
	width: 100%;
	display: grid;
	place-items: center;
	overflow: hidden;
}

$animationSpeed: 40s;

.slide-track{
	display: flex;
	width: (calc(500px * 12));
	animation: scroll $animationSpeed linear infinite;
}

.slide{
	height: 100px;
	width: 500px;
	display: flex;
	align-items: center;
	// padding: 4px 6px;
}

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-500px * 6))}
}

.slider::before,
.slider::after {
	content: '';
	position: absolute;
	top: 0;
	height: 100%;
	width: 15%;
	z-index: 2;
}

.slider::before {
	left: 0;
	top: 0;
}

.slider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
}

span{
	width: 100%;
}

img{
	width: 40%;
	height: 60%;
	object-fit: contain;
}